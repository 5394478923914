import {signOut} from 'aws-amplify/auth'
import {useRouter} from 'next/navigation'

import mixpanelService from '@atorie/api/mixpanel'
import {useAuthUser, useIsGuest} from '@atorie/hooks'
import {Button} from '@atorie/ui/buttons'
import {Sheet} from '@atorie/ui/overlays'
import {Link} from '@atorie/ui/typography'

import {Logo} from '../Logo'

interface SidebarDrawerProps {
  isOpen: boolean
  onClose: () => void
}

export function SidebarDrawer({isOpen, onClose}: SidebarDrawerProps) {
  const isGuestUser = useIsGuest()
  const router = useRouter()
  const {refetch} = useAuthUser()

  const mainStoreUrl = process.env.NEXT_PUBLIC_MAIN_STORE_URL ?? ''

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      className="bg-white text-zinc-800 shadow-[0px_0px_8px_2px_rgba(0,0,0,0.2)]"
    >
      {({close}) => {
        return (
          <div className="flex h-full flex-col">
            <header className="flex h-12 w-full items-center">
              <Logo />
            </header>

            <div className="flex flex-col pt-4">
              <nav className="flex flex-col ">
                <div className="divide-y divide-white/20 px-2">
                  <div>
                    <Button
                      variant="ghost"
                      className="h-12 justify-start text-zinc-800 no-underline"
                      onPress={() => {
                        router.push('/')
                        onClose()
                      }}
                    >
                      Home
                    </Button>
                  </div>
                  <div>
                    <Button
                      variant="ghost"
                      className="h-12 justify-start text-zinc-800 no-underline"
                      onPress={() => router.push('/')}
                    >
                      For you
                    </Button>
                  </div>
                  {!isGuestUser && (
                    <>
                      <div>
                        <Button
                          variant="ghost"
                          className="h-12 justify-start text-zinc-800 no-underline"
                          onPress={() => {
                            router.push('/reset-password')
                          }}
                        >
                          Reset Password
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="ghost"
                          className="h-12 justify-start text-zinc-800 no-underline"
                          onPress={async () => {
                            mixpanelService.track('USER_SIGNED_OUT')
                            signOut()
                            await refetch()
                          }}
                        >
                          Sign out
                        </Button>
                      </div>
                    </>
                  )}
                </div>

                <div className="w-full px-6 pt-4">
                  {isGuestUser && (
                    <Button
                      variant="primary"
                      className="avenir w-full text-lg"
                      onPress={() => {
                        router.push(`/login`)
                      }}
                    >
                      Log in
                    </Button>
                  )}
                </div>
              </nav>
            </div>

            <div className="flex-1" />

            <footer className="p-4">
              <div className="flex flex-col divide-y ">
                <Link
                  href="mailto:tech@shopatorie.com"
                  variant="unstyled"
                  className="py-2 text-sm font-normal text-zinc-800 underline"
                >
                  tech@shopatorie.com
                </Link>
                <Link
                  href={`${mainStoreUrl}/terms-of-service`}
                  target="_blank"
                  variant="unstyled"
                  className="py-2 text-sm font-normal text-zinc-800 underline"
                >
                  Terms of Service
                </Link>
                <Link
                  href={`${mainStoreUrl}/privacy-policy`}
                  target="_blank"
                  variant="unstyled"
                  className="py-2 text-sm font-normal text-zinc-800 underline"
                >
                  Privacy Policy
                </Link>
                <Link
                  href={`${mainStoreUrl}`}
                  target="_blank"
                  variant="unstyled"
                  className="py-2 text-sm font-normal text-zinc-800/50 no-underline"
                >
                  Created by AtorieLabs, Inc.
                </Link>
              </div>
            </footer>
          </div>
        )
      }}
    </Sheet>
  )
}
