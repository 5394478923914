import {signInWithRedirect} from 'aws-amplify/auth'
import {FcGoogle} from 'react-icons/fc'

import {cx} from '@atorie/core/cva'

import {Button} from './button'

export interface SignInWithGoogleProps {
  className?: string
}

export function SignInWithGoogleButton({className}: SignInWithGoogleProps) {
  return (
    <div
      className={cx(
        'relative isolate flex items-center justify-center',
        className,
      )}
    >
      {/* <div
        className={cx(
          'absolute inset-2.5 -z-20 opacity-0 blur-md',
          'bg-google',
        )}
      /> */}
      <Button
        variant="primary"
        className={cx(
          'avenir relative flex h-14 w-full items-center justify-center gap-3',
        )}
        onPress={() => {
          signInWithRedirect({provider: 'Google'})
        }}
      >
        <FcGoogle />
        <span className="text-lg">Continue with Google</span>
      </Button>
    </div>
  )
}

export default SignInWithGoogleButton
